.footer {
  background-color: var(--black);
  width: 100%;
  color: var(--white);
  position: relative;
  bottom: 0;
}

.footer h1 {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Spline Sans', sans-serif;
  font-weight: 500;
  font-size: 4vmax;
}

.footer .footerContent {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}

.footerContent ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footerContent ul li a {
  margin: 0 1rem;
  font-weight: 800;
  color: var(--white);
  font-weight: 700;
}

.footerContent ul li a:hover {
  color: var(--green);
}

.footerContent .copyright h3 {
  font-family: 'Times New Roman', Times, serif;
}
