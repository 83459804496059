.portfolioPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto 2rem;
  max-width: 1000px;
}
.content h1 {
  color: var(--black);
  font-family: 'Bangers', sans-serif;
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.cardBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.cardBox .portfolioCard {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 1rem;
  background-color: var(--white);
}
.portfolioCard .portfolioImg {
  display: flex;
  justify-content: center;
}

.cardBox .portfolioCard img {
  height: 100px;
}
.logoImg {
  border-radius: 50%;
}
.cardBox .portfolioCard h3 {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
}

.portfolioCard a:hover {
  color: var(--dark-green);
}

.cardBox .portfolioCard p {
  line-height: 18px;
}
