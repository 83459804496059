.header {
  width: 100%;
  height: 6rem;
  background-color: black;
  color: var(--green);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 5rem;
  border-radius: 50%;
}

.header h1 {
  font-size: 5vmax;
  font-family: 'Bangers', cursive;
  text-align: center;
  color: var(--green);
  letter-spacing: 2px;
}
.navLinks ul {
  display: flex;
  flex-direction: row;
}

.navLinks ul li a {
  color: var(--white);
  font-family: 'Spline Sans', sans-serif;
  font-weight: 700;
  margin: 0 0.5rem;
}

.navLinks ul li a:hover {
  color: var(--green);
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 4vmax;
  }
  .navLinks ul {
    flex-flow: column nowrap;
  }
}
