.homePage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
.content .quote {
  display: flex;
  max-width: 700px;
  padding: 0 1rem;
}

.quote h2 {
  font-family: 'Moon Dance', cursive;
  font-weight: 600;
  font-size: 2.2rem;
}

.quote h2 span {
  font-family: 'Spline Sans';
  font-weight: 400;
}
.content .mainBox {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 800px;
  justify-content: space-evenly;
}

.mainBox .imgBox img {
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
}

.mainBox .mainInfo {
  width: 300px;
}

.mainBox .mainInfo p {
  margin: 0;
  letter-spacing: 0.8px;
  line-height: 28px;
  font-size: 1.25rem;
  font-family: 'Spline Sans', sans-serif;
}
