:root {
  --black: #000000;
  --white: #fefefe;
  --green: #e5fbc5;
  --dark-green: #1f6750;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--white);
  font-family: 'Spline Sans', sans-serif;
  font-weight: 400;
  margin: 0;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Spline Sans', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: default;
}
