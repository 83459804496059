.contactPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 2rem auto;
}
.content .contact {
  margin: 0 2%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 2rem 1rem;
}

.content h1 {
  color: var(--black);
  font-family: 'Bangers', sans-serif;
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.content a {
  font-size: 3vmax;
  font-family: 'Spline Sans', sans-serif;
}

.content a:hover {
  color: var(--dark-green);
}
.content a .email {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.email span {
  margin-left: 16px;
  font-family: 'Spline Sans', sans-serif;
}

.content p {
  font-family: 'Spline Sans', sans-serif;
  font-size: 1.5rem;
  letter-spacing: 1px;
  line-height: 2rem;
}
